<section id="ausbildung">
    <div class="title">
        <h2>Meine Ausbildungen:</h2>
    </div>

    <div class="list-holder">
        <div class="list-item">
            <div class="logo-holder">
                <img src="../../../assets/img/logo_612.png" alt="PROGmasters bootcamp logo">
            </div>
            <div class="list-text">
                <p>Frontend Ausbildung (<a href="https://progmasters.hu/" target="_blank">PROGmasters</a>, Budapest, 2021)</p>
            </div>

        </div>
        <div class="list-item">
            <div class="logo-holder">
                <img src="../../../assets/img/logo_semmelweis.png" alt="Semmelweis Uni logo">
            </div>
            <div class="list-text">
                <p>Studium der Physiotherapie mit Abschluß BSc (<a href="https://semmelweis.hu/" target="_blank">Semmelweis Universität</a>, Budapest, 2008)</p>
            </div>

        </div>
        <div class="list-item">
            <div class="logo-holder">
                <img src="../../../assets/img/logo_corvinus.png" alt="Corvinus Uni logo">
            </div>
            <div class="list-text">
                <p>Studium der Wirtschaftswissenschaften mit Abschluß MA (<a href="https://www.uni-corvinus.hu/" target="_blank">Corvinus Universität</a>, Budapest, 1999)</p>
            </div>
        </div>
    </div>

</section>