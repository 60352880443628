<section>
    <div class="title" id="erfahrung">
        <h2>Meine Berufserfahrungen:</h2>
    </div>
    <div class="list-holder">
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Im Anschluß an mein Studium der Wirtschaftwissenschaften habe ich im Finanzwesen gearbeitet.</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Nach der Geburt meines ersten Sohnes, der leider mit einer seltenen Muskelerkrankung zur Welt kam, studierte ich Physiotherapie, weil ich nach Möglichkeiten suchte, ihm zu helfen.</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Danach habe ich Kurse für Eltern angeboten, um ihnen mein erworbenes Wissen im Bezug auf das Handling von Babys weiterzugeben.</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>In dieser Zeit habe ich begonnen meine erste Webseite zu erstellen und später darüber auch online Kurse anzubieten. (Eine meiner aktuellen Webseiten könnt ihr <a href="https://integrativoszteopatia.com/" target="_blank">hier sehen.</a>)</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Danach habe ich als freie Mitarbeiterin im Bereich der Automatisierung von Marketingprozessen in einer kleinen Agentur in Budapest gearbeitet.</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Durch die Arbeit in der Agentur wurde mir bewusst dass ich sehr viel Spaß an der Webentwicklung habe und mein Wissen im Bereich der Frontend Entwicklung vertiefen wollte.</p>
        </div>
    </div>
</section>