import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
declare var $: any;

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css'],
  animations: [
    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide',   style({
        opacity: 0,
        transform: "translateX(-50%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ])
  ]
})
export class TopComponent implements OnInit {
  chevronUp = faChevronUp;
  state = 'hide';

  constructor(public el: ElementRef) { }

  ngOnInit(): void {
  }

  goUp() {
    $("html, body").animate({scrollTop:0}, "slow");
    return false;
  }

  @HostListener('window:scroll', ['$event'])
    checkScroll() {
      if (window.pageYOffset >= 200) {
        this.state = 'show'
      } else {
        this.state = 'hide'
      }
    }  

}
