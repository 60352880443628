<section id="kontakt">
    <div class="contact">
        <div class="contact-text">
            <p>Ich würde mich sehr freuen, wenn ihr mir die Chance geben würdet, in den Bereich von Webentwicklung bei euch einzusteigen! </p>
            <p>+36 20 8289360</p>
            <p>
                <a href="mailto:borbala.gyulai@gmail.com">borbala.gyulai@gmail.com</a>
            </p>
        </div>
    </div>
</section>