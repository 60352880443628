<section>
    <div class="title">
        <h2>Warum möchte ich <br> ein Frontend-Punk werden?</h2>
    </div>
    <div class="content-holder">

        <div class="list-holder">
            <div class="list-item">
                <div class="icon-holder">
                    <fa-icon [icon]="arrowRight"></fa-icon>
                </div>
                <p>ich habe in den letzten Monaten eine Frontend Ausbildung gemacht (<a href="https://progmasters.hu/" target="_blank">PROGmasters</a>)</p>
            </div>
            <div class="list-item">
                <div class="icon-holder">
                    <fa-icon [icon]="arrowRight"></fa-icon>
                </div>
                <p>ich kann es nicht erwarten das erlernte anzuwenden</p>
            </div>
            <div class="list-item">
                <div class="icon-holder">
                    <fa-icon [icon]="arrowRight"></fa-icon>
                </div>
                <p>ich möchte gerne in einem kreativen, innovativen Team arbeiten</p>
            </div>
            <div class="list-item">
                <div class="icon-holder">
                    <fa-icon [icon]="arrowRight"></fa-icon>
                </div>
                <p>ich möchte nicht nur arbeiten, sondern auch dazu lernen und meine Kenntnisse mit eurer Hilfe vertiefen</p>
            </div>
            <div class="list-item">
                <div class="icon-holder">
                    <fa-icon [icon]="arrowRight"></fa-icon>
                </div>
                <p>ich bin mir sicher dass ich bei euch viele neue Erfahrungen sammeln kann, aber auch ich mit meinem Teamgeist und meinen Kompetenzen das Team bereichern werde</p>
            </div>

        </div>
        <div class="img-holder">
            <img src="../../../assets/img/004.jpg" alt="Borbála Gyulai">
        </div>
    </div>
</section>