<section>
    <div class="mobile">
        <p class="name">Borbála Gyulai</p>
        <p> – Junior Frontend Developer</p>
    </div>
    <nav>

        <div class="name-holder ">
            <p class="name">Borbála Gyulai </p>
            <p>– Junior Frontend Developer</p>
        </div>
        <div class="menu-container">
            <ul class="menuitems-holder">
                <li>
                    <a href="#ausbildung">Ausbildungen</a>
                </li>
                <li>
                    <a href="#erfahrung">Berufserfahrung</a> </li>
                <li><a href="#skills">Fähigkeiten</a></li>
                <li><a href="#kontakt">Kontakt</a></li>
            </ul>
        </div>
    </nav>
</section>