<section>
    <div class="title" id="skills">
        <h2>Kompetenzen und Fähigkeiten:</h2>
    </div>
    <div class="list-holder">
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Ich habe HTML5, CSS3, Javascript und Angular gelernt – gerne möchte ich meine <a href="https://team-9-basic-project.web.app/">Projektarbeit</a> vorstellen, die wir in einem 2er-Team gemacht haben.</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Ich habe sehr viel Spaß und Freude an der Webentwicklung und auch in diesem Bereich möchte mich sehr gerne weiterentwickeln.</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Lösungsorientierte Denkweisen sind mir grundsätzlich in meinem Leben nicht fremd ;)</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Meine Muttersprache ist Ungarisch, und ich spreche gut Englisch.</p>
        </div>
        <div class="list-item">
            <div class="icon-holder">
                <fa-icon [icon]="arrowRight"></fa-icon>
            </div>
            <p>Im Sommer 2019 habe ich begonnen Deutsch Unterricht zu nehmen. Mein Deutsch ist immer noch nicht perfekt, aber ich arbeite weiter daran, so spreche ich mit meinem Freund Deutsch und nehme auch weiterhin Unterricht.</p>
        </div>
    </div>
</section>